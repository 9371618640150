import React from "react";
import dummyImage from "../../assets/img/dummyimage.png";
import defaultProduct from "../../assets/img/DefaultProduct.jpg";

const PreviewImageFiles = (props) => {
    const { type, url, title} = props;
    if(type === "image") {
        return(<div className="previewImage">
            <img 
                src={url} alt={title} 
                onError={(e)=> {e.target.src = (type === "productImage" ? defaultProduct : dummyImage)}}
            />
        </div>)
    } else {
        return(<div className="previewImage">
        {url === undefined ? (<img src={defaultProduct} alt="dummyImage" />) : (<img src={url} alt={title} onError={(e)=> {e.target.src = defaultProduct}} />)} 
    </div>)
    }
}

export default PreviewImageFiles;