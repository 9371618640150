/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { SINGLE_POSTER, CHECK_OUT_DOUBLE, PRE_SELECTED_PRODUCT } from "../Shared/constant";
import { SelectMultiPosters, AddToCartPosters } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../Shared/AuthLayout";

const SelectBundleAmount = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const PreselectMulti = useSelector((state) => state.allReducers.PreselectMultiPoster);
    const ProductIdArray = useSelector((state) => state.allReducers.productidObjects);

    // get bundle amount
    const GetBundleAmt = (e) => {
        let payload = {
            cart: e.target.value,
            amount: e.target.value === "2" ? 5 : e.target.value === "4" ? 10 : e.target.value === "10" ? 25 : e.target.value === "20" ? 50 : e.target.value === "40" ? 100 : 250
        }
        const PreselectedProduct = [];
        if(PreselectMulti) {
            setTimeout(() => {
                window.axios.get(`${PRE_SELECTED_PRODUCT}${payload.amount}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).then(function (result) {
                    if(result.data) {
                        result.data.data.products.map((elm) => {
                            PreselectedProduct.push(ProductIdArray[elm])
                        });
                        dispatch(AddToCartPosters(PreselectedProduct));
                        dispatch(SelectMultiPosters(payload));
                        Navigate(CHECK_OUT_DOUBLE);
                    }
                })  
                .catch(function (result) {
                    dispatch(AddToCartPosters([]));
                });
            }, 500);
        } else {
            setTimeout(() => {
                dispatch(SelectMultiPosters(payload));
                Navigate(SINGLE_POSTER);
            }, 500);
        };
    };

    return(<AuthLayout><div className="choosebundleModal">
        <div className="container">
            <div className="row">
                <h1>Choose A Bundle Amount</h1>
                <div className="chosseCustomNumber">
                    <div className="formgroup">
                        <label className="CustomRadio">$5
                            <input type="radio" value="2" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup">
                        <label className="CustomRadio">$10
                            <input type="radio" value="4" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup">
                        <label className="CustomRadio">$25
                            <input type="radio" value="10" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup">
                        <label className="CustomRadio">$50
                            <input type="radio" value="20" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup">
                        <label className="CustomRadio">$100
                            <input type="radio" value="40" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="formgroup">
                        <label className="CustomRadio">$250
                            <input type="radio" value="105" onChange={(e) => GetBundleAmt(e)} name="radio" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div></AuthLayout>)
};

export default SelectBundleAmount;