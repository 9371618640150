import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from '../ScrollToTop';
import { useSelector } from "react-redux";

const AuthLayout = (props) => {
    const { setLoginWithForm, setLoginSignupSet, children } = props;
    const { accessToken, profiledata, is_login } = useSelector((state) => state.allReducers);

    return(<>
          <Header setLoginSignupSet={setLoginSignupSet} setLoginWithForm={setLoginWithForm} accessToken={accessToken} profiledata={profiledata} is_login={is_login} />
          {children}
          <ScrollToTop />
          <Footer />
    </>)
}

export default AuthLayout;