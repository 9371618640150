/* eslint-disable */
import React from "react";
import PurchesSingleImage from "../../assets/img/posters/From-Social-Anxiety-to-Social-Butterfly-in-90-Days-2.webp";
import PurchesBundleImage1 from "../../assets/img/posters/Beating Worry And Anxiety - 2.webp";
import PurchesBundleImage2 from "../../assets/img/posters/Understanding Social Anxiety - 2.webp";
import { Link } from "react-router-dom";
import { SINGLE_OR_CHOOSE_URL, SINGLE_POSTER } from '../../components/Shared/constant';

const PurchesPoster = () => {
    return(<section className="PurchesPostersction">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="purchesswraps">
                        <div className="row">
                            <div className="col-6">
                                <p className="purchespassage">At Bundles of Books, we believe that knowledge is power.  That's why we created this site full of affordable e-books to educate readers on a variety of topics such as:</p>
                                <ul className="purchesslist">
                                    <li>Career</li>
                                    <li>Faith</li>
                                    <li>Fears</li>
                                    <li>Finances</li>
                                    <li>Happiness</li>
                                    <li>Health and Fitness</li>
                                    <li>Parenting</li>
                                    <li>Mental Health</li>
                                    <li>Motivation and Inspiration</li>
                                    <li>Relationships</li>
                                    <li>Retirement</li>
                                    <li>Self Esteem</li>
                                    <li>And More!</li>
                                </ul>
                                <p className="purchespassage">We believe that everyone deserves true happiness and should strive towards self improvement, and it's our hope that these digital e-books will be a key element to get you there. Our goal is to assist you in unlocking your true potential and living a life you love.</p>
                            </div>
                            <div className="col-6">
                                <div className="purchessingletypes">
                                    <img src={PurchesSingleImage} alt="Purches Single Image" />
                                    <Link className="btn Purchasebtn" to={SINGLE_POSTER} >Purchase Single E-Books</Link>
                                </div>
                                <div className="purchesBundletypes">
                                    <div className="purchesBundleGroups">
                                        <img src={PurchesBundleImage2} alt="Purches Single Image" />
                                        <img src={PurchesSingleImage} alt="Purches Single Image" />
                                        <img src={PurchesBundleImage1} alt="Purches Single Image" />
                                    </div>
                                    <Link className="btn Purchasebtn" to={SINGLE_OR_CHOOSE_URL} >Purchase Bundle of Books</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}

export default PurchesPoster;