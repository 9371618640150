/* eslint-disable */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/media.css";
import About from './components/About';
import Checkout from './components/Checkout';
import Privacy from './components/Privacy';
import Userdatadeletion from './components/Userdatadeletion';
import RefundPolicy from './components/Refundpolicy';
import TermsConditions from './components/TermsConditions';
import SelectBundleAmount from './components/SelectBundleAmount';
import SelectOrChoose from './components/SelectOrChoose';
import SinglePoster from './components/SinglePoster';
import Contact from './components/Contact';
import Account from './components/Account';
import ResetPassword from './components/ResetPassword';
import { LOGIN_URL, MY_ACCOUNT, GET_CATEGORY_LISTS_API_URL, USER_DATA_DETECTION, LOGIN_POSTER_API_URL, GET_PRODUCT_API_URL, ABOUT_URL, PRIVACY_POLICY, GET_PROFILE_API_URL, REFUND_POLICY, CONTACT, TERMS_CONDITIONS, SINGLE_OR_CHOOSE_URL, SINGLE_POSTER, CHECK_OUT_SINGLE, CHECK_OUT_DOUBLE, CHOOSE_BUNDLE_AMOUNT, RESET_PASSWORD } from './components/Shared/constant';
import LoginSignupModal from './components/LoginSignupModal';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, getIdWiseCategoryList, GetCategory, CallLogoutUser, GetProductsIdWise, getAccessToken, checkLogin, getUUID } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import ResponsiveSidebar from './components/Shared/ResponsiveSidebar';
import CommonLoader from './components/common/CommonLoader';
import Login from './components/Login';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/* eslint-disable no-mixed-operators */
export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function App() {
  const [ heightScroll, setheightScroll ] = useState(0);
  const { accessToken, is_login, profiledata, uuId, GeoLocationRespo, checkCache } = useSelector((state) => state.allReducers);
  const dispatch = useDispatch();
  const LocationUrl = window.location.href.split("/")[3];
  const [ LoginSignupSet, setLoginSignupSet ] = useState("Login");
  const [ loginWithForm, setLoginWithForm ] = useState(false);
  window.addEventListener('scroll', function(){
    setheightScroll(this.scrollY);
  });
  const [ LoaderStart, setLoaderStart ] = useState(false);

  // get unique id every time   
  useEffect(() => {
     if(uuId === "") {
        dispatch(getUUID(uuidv4()));
     } 
  }, [ uuId ]);

  useEffect(() => {
    if(accessToken !== "") {
        // get user api
        window.axios.get(`${GET_PROFILE_API_URL}`, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + accessToken,
          }
        }).then(function (result) {
            dispatch(getUser(result?.data?.data));
        })  
        .catch(function (result) {
            if(result?.response?.data?.detail === "Token expired.") {
              AccessTokenCheckLogout();
            };
        });
    } 
    
    // get product list
    window.axios.get(`${GET_PRODUCT_API_URL}/5000/1/none`, {
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      }
    }).then(function (result) {
        dispatch(GetProductsIdWise(result.data.data));
    })  
    .catch(function (result) {
        dispatch(GetProductsIdWise([]));
    });

    // get category api
    window.axios.get(`${GET_CATEGORY_LISTS_API_URL}/5000/1/none`, {
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      }
    }).then(function (result) {
        dispatch(GetCategory(result.data.data));
        dispatch(getIdWiseCategoryList(result.data.data));
    })  
    .catch(function (result) {
        dispatch(getIdWiseCategoryList([]));
        dispatch(GetCategory([]));
    });

    if(checkCache === undefined || checkCache === null) {
        dispatch(CallLogoutUser());
    };
  }, [ accessToken ]);

  // if accessToken expire then page will logut and redirect to home page 
  const AccessTokenCheckLogout = () => {
    setTimeout(() =>  Navigate(ABOUT_URL), 200);
    localStorage.removeItem("accessToken");
    dispatch(getAccessToken(""));
    dispatch(checkLogin("no"));
    dispatch(getUser(""));
  };

  // input field space issue 
  document.getElementById("input")?.addEventListener('keydown', function (e) {
    if (this.value.length === 0 && e.which === 32) e.preventDefault();
  });

  return (<React.Fragment>
        <div className={heightScroll > 150 ? "wrapper scrollbody" : "wrapper"}>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Navigate replace to={ABOUT_URL}/>} />
                    <Route path={LOGIN_URL} element={<Login loginWithForm={loginWithForm} setLoginWithForm={setLoginWithForm} LoginSignupSet={LoginSignupSet} setLoginSignupSet={setLoginSignupSet} setLoaderStart={setLoaderStart} />} />
                    <Route path={ABOUT_URL} element={<About setLoginSignupSet={setLoginSignupSet} setLoginWithForm={setLoginWithForm} title="Index Page" />} />
                    <Route path={`${LOGIN_POSTER_API_URL}/:roomId`} element={<About setLoginSignupSet={setLoginSignupSet} setLoginWithForm={setLoginWithForm} title="Index Page" />} />
                    <Route path={SINGLE_OR_CHOOSE_URL} element={<SelectOrChoose  />} />
                    <Route path={SINGLE_POSTER} element={<SinglePoster />} />
                    <Route path={CHECK_OUT_SINGLE} element={<Checkout />} />
                    <Route path={CHECK_OUT_DOUBLE} element={<Checkout />} />
                    <Route path={CHOOSE_BUNDLE_AMOUNT} element={<SelectBundleAmount />} />
                    <Route path={PRIVACY_POLICY} element={<Privacy />} />
                    <Route path={USER_DATA_DETECTION} element={<Userdatadeletion />} />
                    <Route path={TERMS_CONDITIONS} element={<TermsConditions />} />
                    <Route path={REFUND_POLICY} element={<RefundPolicy />} />
                    <Route path={CONTACT} element={<Contact />} />
                    <Route path={MY_ACCOUNT} element={(accessToken !== "" && is_login !== "no") ? <Account /> : <Navigate replace to={ABOUT_URL}/>} />
                    <Route path={`${RESET_PASSWORD}/:roomId`} element={<ResetPassword />} />
                </Routes>
                <LoginSignupModal loginWithForm={loginWithForm} setLoginWithForm={setLoginWithForm} LoginSignupSet={LoginSignupSet} setLoginSignupSet={setLoginSignupSet} setLoaderStart={setLoaderStart} />
                <ResponsiveSidebar profiledata={profiledata} accessToken={accessToken} is_login={is_login}/>
            </BrowserRouter>
            <ToastContainer />
            {LoaderStart && <CommonLoader />}
        </div>
  </React.Fragment>);
}

export default App;
