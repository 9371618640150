/* eslint-disable */
import React, { useState } from "react";
import PosterList from "../common/PosterList/PosterList";
import { useSelector } from "react-redux";
import AuthLayout from "../Shared/AuthLayout";

const SinglePoster = () => {
    const PosterMulti = useSelector((state) => state.allReducers.selectPosterMulti);
    const categorylist = useSelector((state) => state.allReducers.categorylist);
    const [ SearchCatergory, setSearchCatergory ] = useState("");

    return(<AuthLayout><section className="singlepostersection">
        <div className="headerwithfilter">
            {PosterMulti.cart === 1 ? <h1 className="sectionheader">Purchase eBooks </h1> : <h1 className="sectionheader">Please Choose {PosterMulti.cart} Books</h1>}
            <div className="searchfilterbookswraps">
                <h4>Search Books</h4>
                <select value={SearchCatergory} onChange={(e) => setSearchCatergory(e.target.value)}>
                    <option value="">Search by category</option>
                    {categorylist && categorylist.map((elm, index) => {
                        return(<option key={index} value={elm.id}>{elm?.name}</option>)
                    })}
                </select>
                <button className="btn resetbtn" onClick={() => setSearchCatergory("")}>Reset Filter</button>
            </div>
        </div>
        <PosterList SearchCatergory={SearchCatergory} />
    </section></AuthLayout>)
}

export default SinglePoster;