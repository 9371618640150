/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import PurchesSingleImage from "../../assets/img/posters/From-Social-Anxiety-to-Social-Butterfly-in-90-Days-2.webp";
import PurchesBundleImage1 from "../../assets/img/posters/Beating Worry And Anxiety - 2.webp";
import PurchesBundleImage2 from "../../assets/img/posters/Understanding Social Anxiety - 2.webp";
import { CHOOSE_BUNDLE_AMOUNT } from "../Shared/constant";
import { preSelectMultiPoster, AddToCartPosters } from "../../redux/actions";
import { useDispatch } from "react-redux";
import AuthLayout from "../Shared/AuthLayout";

const SelectOrChoose = () => {
    const dispatch = useDispatch();

    return(<AuthLayout><section className="singlepostersection PosterBundleswraper">
    <h1 className="sectionheaders">Purchase eBook Bundles</h1>
    <div className="posterlistwraps">
        <div className="container-fluid custom-container-fluid">
            <div className="purchesswraps">
                <div className="row">
                    <div className="col-md-6">
                        <div className="purchesBundletypes">
                            <div className="purchesBundleGroups">
                                <img src={PurchesBundleImage2} alt="Purches Single Image" />
                                <img src={PurchesSingleImage} alt="Purches Single Image" />
                                <img src={PurchesBundleImage1} alt="Purches Single Image" />
                            </div>
                            <Link 
                                to={CHOOSE_BUNDLE_AMOUNT} 
                                onClick={() => {
                                    dispatch(preSelectMultiPoster(true));
                                    dispatch(AddToCartPosters([]))
                                }} 
                                className="btn Purchasebtnbig"
                            > 
                                GET A PRESELECTED BUNDLE
                            </Link>                                    
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="purchesBundletypes">
                            <div className="purchesBundleGroups">
                                <img src={PurchesBundleImage2} alt="Purches Single Image" />
                                <img src={PurchesSingleImage} alt="Purches Single Image" />
                                <img src={PurchesBundleImage1} alt="Purches Single Image" />
                            </div>
                            <Link to={CHOOSE_BUNDLE_AMOUNT} onClick={() => dispatch(preSelectMultiPoster(false))} className="btn Purchasebtnbig">CUSTOMIZE YOUR BUNDLE</Link>                                    
                            <a href="https://www.sweepscoins.games/" target="_blank" className="choosebokkctgrlink">Choose Your E-book Categories</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section></AuthLayout>)
}

export default SelectOrChoose;