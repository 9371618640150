/* eslint-disable */
import React from "react";
import SuccessFullIcon from "../../../assets/img/sucessfullyicon.gif";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SINGLE_POSTER } from '../../Shared/constant';
import { useSelector, useDispatch } from "react-redux";
import { cardDetailsGet, customerDetailsGet, SelectMultiPosters } from "../../../redux/actions";

const SuccessModal = (props) => {
    const { SuccessPopup, setSuccessPopup, Ammount } = props;
    const dispatch = useDispatch();
    const { cardDetails, customerDetail } = useSelector((state) => state.allReducers);

    const CartClearStore = () => {
        dispatch(SelectMultiPosters({cart: 1,amount: 3.99}));
        dispatch(cardDetailsGet({}));
        dispatch(customerDetailsGet({}));
    };

    return(<div className="successModalwraps">
        <div className="successpymentwrapper">
            <div className="succesiocn">
                <img src={SuccessFullIcon} alt="correct icon" />
            </div>
            <h4>Thank You!</h4>
            <p>We received your payment of <b>${customerDetail?.total_amount}</b> <br />
            We will send a confirmation email shortly to : <b>{customerDetail?.email}</b></p>
            <div className="tarnsitionId">
                <span>Transaction id</span> : {cardDetails?.transaction_id}
            </div>
            <div className="successpymentbutns">
                <Link className="btn cancelbtn" to={SINGLE_POSTER} onClick={() => CartClearStore()} >Explore More</Link>
                <Button className="savebtn" onClick={() => setSuccessPopup({...SuccessPopup, open: false})}>Cancel</Button>
            </div>
        </div>
    </div>)
}

export default SuccessModal;