/* eslint-disable */
import React from "react";
import DigitalPosters from "./DigitalPosters";
import PurchesPoster from "./PurchesPoster";
import AuthLayout from "../Shared/AuthLayout";

const About = (props) => {
    const { setLoginWithForm, setLoginSignupSet } = props;

    return(<AuthLayout setLoginWithForm={setLoginWithForm} setLoginSignupSet={setLoginSignupSet} >
        <section className="aboutuspage">
            <DigitalPosters />
            <PurchesPoster />
        </section>
    </AuthLayout>)
}

export default About;