/* eslint-disable */
import React from "react";

const DigitalPosters = () => {
    
    return(<section className="digitalposters">
        <div className="videoOvarlay"> </div>
        <div className="digitalposterssliders">
            <div className="commonheader">
                <h1>A Motivational And Self Improvement</h1>
                <h4>DIGITAL E-BOOK STORE</h4>
            </div>
        </div>
    </section>)
}

export default DigitalPosters;