/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHECK_OUT_SINGLE, CHECK_OUT_DOUBLE, GET_PRODUCT_API_URL, API_URL } from "../../Shared/constant";
import { AddToCartPosters, getSinglePosters } from "../../../redux/actions";
import BookPreviewModal from "../BookPreviewModal";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import PreviewImageFiles from "../../common/PreviewImageFiles";

const PosterList = (props) => {
    const { SearchCatergory, } = props;
    const { singlposterslist, selectPosterMulti, accessToken, categoryObjects } = useSelector((state) => state.allReducers);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [ SelectedPosterState, setSelectedPosterState ] = useState([]);
    const [ PreviewBookModalState, setPreviewBookModalState ] = useState(false);
    const [ bookSummary, setBookSummary ] = useState("")
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ listLimit, setListLimit ] = useState(50);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        window.axios.get(`${GET_PRODUCT_API_URL}/${listLimit}/${currentPage}/${SearchCatergory === "" ? "none" : SearchCatergory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
          }).then(function (result) {
              dispatch(getSinglePosters(result.data.data));
              setPaginationLength(result.data.pagination);
          })  
          .catch(function (result) {
              dispatch(getSinglePosters([]));
          });
    }, [ SearchCatergory, currentPage ]);

    // add to cart function
    let PostersVar = [...SelectedPosterState];
    const AddToCart = (elm) => {
        PostersVar.push(elm);
        setSelectedPosterState(PostersVar);
        if(selectPosterMulti.cart === 1) {
            dispatch(AddToCartPosters(PostersVar));
            setTimeout(() => Navigate(CHECK_OUT_SINGLE), 100);
        } else if(selectPosterMulti.cart === PostersVar.length.toString()) {
            dispatch(AddToCartPosters(PostersVar));
            setTimeout(() => Navigate(CHECK_OUT_DOUBLE), 100);
        } 
    };

    // get selected posters id
    const MemberListArray = [];
    SelectedPosterState.forEach(element => {
        MemberListArray.push(element.id);
    });


    // category list show
    const CategoryList = (category) => {
        const CategorySplite = category && category?.split(",");
        const CategorySpliteGet = CategorySplite && CategorySplite?.map((elm) => {
            return categoryObjects[elm]?.name;
        }); 

        return CategorySpliteGet?.toString();
    };

    const PreviewBookModal = (summary) => {
        setBookSummary(summary);
        setPreviewBookModalState(true);
    };

    return(<div className="posterlistwraps">
        <div className="container-fluid custom-container-fluid">
            <div className="col-md-12">
                <div className="poserrbooksrow">
                    {singlposterslist?.length > 0 ? singlposterslist?.map((elm, index) => {
                        return(<div className="col-md-2 col-sm-4 col-xs-12" style={{ padding: "0px 20px" }} key={index}>
                            <div className="posterblogswrp">
                                <PreviewImageFiles type="productImage" url={elm?.product_img_path} title={elm.name}/>
                                <div className="posterblogswrpdetails">
                                    <h5>{elm?.name}</h5>
                                    <p>{CategoryList(elm?.category)}</p>
                                    <div className="postaddcart">
                                        <h4>${elm?.price}</h4>
                                        <span onClick={() => PreviewBookModal(elm.description)}>Learn More</span>
                                        <Button 
                                            className="btn addtocart" 
                                            onClick={() => AddToCart(elm)}
                                            disabled={MemberListArray.includes(elm?.id) === true ? true : false}
                                        >Add To Cart</Button>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }) : (<h4 className="notdatafound">No Books Found</h4>)}
                </div>
            </div>
        </div>
        <div className='paginationCustom'>
            <Pagination
                currentPage={parseInt(currentPage)}
                itemsPerPage={parseInt(paginationLength?.record_limit)}
                onPageChange={(pageNumber) => currentFunction(pageNumber)}
                totalItems={parseInt(paginationLength?.total_records)}
                pageNeighbours={1}
            />
        </div>
        {PreviewBookModalState && (<BookPreviewModal bookSummary={bookSummary} setPreviewBookModalState={setPreviewBookModalState} />)}
        {PreviewBookModalState && (<div className="modalBckgrounds" onClick={() => setPreviewBookModalState(false)}></div>)}
    </div>);
}

export default PosterList;